// Color Scheme
$bg-color: #292929ea;
$white: #ffffff;
$grey: #aaaaaa;
$primary: #283448;
$primary: #202020;
$secondary: #ce0e0e;
$tertiary: #2E1760;
$fourth: #247BA0;

// Hide scrollbar
::-webkit-scrollbar {display: none;}

// HTML tag styles
main { display: block; position: fixed; top: 0; bottom: 0; right: 0; left: 100px;}
body { margin: 0; background-color: $bg-color; font-family: "Avenir Next", Avenir, "Helvetica Neue", sans-serif;}
button { border: none; }

#A {
  background-image: url("../jpg/doom.png");
  background-size: cover;
  background-position: top;
  // height: 100vh;
}

// Media Rules
@media(max-width: 768px) { main { left: 0; top: 5rem; } }

/* Hide scrollbar for Chrome, Safari and Opera */
 .stealth::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.stealth {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

.photo-lense {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
  padding: 20px 0 20px 0;
}

.navbar { height: 5rem;  }

.sidebar {
  background-color: $primary;
  color: $white;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  max-width: 100px;
  // z-index: 100;
  padding: 1rem;
  button { width: 90%; }
}

.menu {
  background-color: $primary;
  color: $white;
  display: block;
  position: fixed;
  top: 5rem;
  bottom: 0;
  left: 0;
  right: 0;
  button { width: 95%; margin-top: 45px; }
}

.table {
  display: table;
  table-layout: fixed;
  font-size: 0.75rem;
  border-radius: 10px;
  border: transparent;
  margin-bottom: 0px;
  tbody { overflow-x: auto; }
  tr { background-color: transparent; color: inherit; }
  th { background-color: transparent; color: inherit; }
  td { background-color: transparent; color: inherit;}
}

.draggable {
  position: absolute;
  text-align: left;
  max-width: 95%;
  width: 400px;
  bottom: 0;
  right: 0;
  border: none;
  border-radius: 10px;
  z-index: 100;
  overflow-x: auto;
  th,td { padding-left: 1rem; padding-right: 1rem; }
}

.explorer { position: absolute; top: 0; bottom: 0; left: 0; width: 100%; z-index: 1; }
#explorer { position: absolute; top: 0; bottom: 0; left: 0; width: 100%; z-index: 1; }
#mapbox { position: absolute; top: 0; bottom: 0; left: 0; width: 100%; z-index: 1; }

// Generic
.contents {display: contents}
.block {display: block;}
.hidden { display: none; }
.icon-sm { font-size: 1.5rem; }
.icon-lg { font-size: 2rem; }

// Mouse action styles
.form-control:focus { background-color: $primary; color: $white; }
.dropdown-item:hover { background-color: $secondary; color: $white; }
.secondary-bg:hover { background-color: $secondary; color: $white; }

// Modal display styles
.modal { display: block; }
.modal-content { border-radius: 10px; border: transparent; }
.modal-header { border: none; }
.modal-footer { border: none; }
.modal-sidebar {
  background-color: #202a3b;
  min-width: 180px;
  max-width: 250px;
  // position: relative;
  // left: 0;
}

// Alignment styles
.container-fluid { height: 100%; width: 100%; }
.center-div { display: flex; text-align: center; justify-content: center; align-items: center; height: inherit }
.table-div { display: inline-flex; text-align: center; }

// Text Styles
.txt { color: $white; text-decoration: none; box-shadow: none; }
.secondary-text { color: $secondary !important; }
::placeholder { color: $grey !important; }

.f-12 { font-size: 12pt;}

.z-99 { z-index: 99;}
.z-100 { z-index: 100;}

// Color styles
.primary-bg { background-color: $primary !important; color: $white !important; }
.secondary-bg { background-color: $secondary !important;;color: $white !important;; }
.transparent { background-color: transparent !important; color: inherit !important; border: none !important;}

// Border styles
.container-border { border: 1.5px solid $secondary; border-radius: 5px; }
.secondary-border { border-color: $secondary; }
.no-border { border: none;}
.radius-sm {border-radius: 10px;}
.radius-md {border-radius: 20px;}


// Misc Deiplay Rules
.btn:disabled { color: $secondary; border-color: $secondary; }